import AppRoutes from './routes'
import { CookiesProvider } from 'react-cookie';

function App() {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <AppRoutes />
    </CookiesProvider>
  )
}

export default App