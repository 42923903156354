import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useState, ReactNode, ReactElement } from "react";
import { Layout } from "../components/layout";
import { Authenticator } from "../components/authenticator";
import { ErrorComponent } from "../components/error";
import { AuthPage } from "../pages/auth";
import { DashboardPage } from "../pages/dashboard";
import { useCookies } from "react-cookie";
import { TOKEN_KEY } from "../constants";

type PrivateRouteProps = {
  children: ReactNode;
  setShowSessionModal: (value: string) => void;
};

export default function AppRoutes() {
  const [showSessionModal, setShowSessionModal] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _setCookie, _removeCookie] = useCookies([TOKEN_KEY]);
  const authToken = cookies.user_token;

  const PrivateRoute = ({
    children,
    setShowSessionModal,
  }: PrivateRouteProps): ReactElement => {
    if (!authToken) {
      setShowSessionModal("Your session has expired. Please log in again.");
      return <Navigate to="/auth" replace />;
    }
    return <>{children}</>;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Navigate to="/dashboard" replace />
            </Layout>
          }
        />
        <Route
          path="/auth"
          element={
            <Authenticator>
              <Layout>
                <AuthPage
                  showSessionModal={showSessionModal}
                  setShowSessionModal={setShowSessionModal}
                />
              </Layout>
            </Authenticator>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Authenticator>
              <Layout>
                <DashboardPage />
              </Layout>
            </Authenticator>
          }
        />
        <Route
          path="/*"
          element={
            <Authenticator>
              <PrivateRoute setShowSessionModal={setShowSessionModal}>
                <ErrorComponent label="Page not found" type="NotFound" />
              </PrivateRoute>
            </Authenticator>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
