import { Navbar } from "./navbar"

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = (props: LayoutProps) => {
  return (
    <div>
      <Navbar />
      <main>{props.children}</main>
    </div>
  )
}
