import { ReactNode } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import { modalStyle } from "./style";

type WarningModalProps = {
  open: boolean;
  message: string;
  onConfirm: () => void;
};

type SuccessModalProps = {
  open: boolean;
  message: string;
  onClose: () => void;
};

type ErrorModalProps = {
  open: boolean;
  message: string;
  onClose: () => void;
};

type ContentModalProps = {
  open: boolean;
  message: string;
  children: ReactNode;
  isLoading: boolean;
  confirmDisabled: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const WarningModal = ({
  open,
  message,
  onConfirm,
}: WarningModalProps) => {
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="warning-modal-title"
        aria-describedby="warning-modal-description"
      >
        <Box sx={{ ...modalStyle, top: "50%" }}>
          <Typography id="warning-modal-title" variant="h6" component="h2">
            Warning
          </Typography>
          <Typography id="warning-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              onClick={onConfirm}
              sx={{
                mr: 2,
                bgcolor: "white",
                color: "black",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export const SuccessModal = ({ open, message, onClose }: SuccessModalProps) => {
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <Typography
            sx={{ color: "lightgreen" }}
            variant="h6"
            color="success"
            gutterBottom
          >
            Success!
          </Typography>
          <Typography sx={{ mb: 2 }}>{message}</Typography>
          <Button
            variant="contained"
            sx={{
              color: "black",
              bgcolor: "white",
              ":hover": {
                backgroundColor: "darkgrey",
                color: "black",
              },
            }}
            onClick={onClose}
          >
            Dismiss
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export const ErrorModal = ({ open, message, onClose }: ErrorModalProps) => {
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <Typography
            sx={{ color: "red" }}
            variant="h6"
            color="error"
            gutterBottom
          >
            Error!
          </Typography>
          <Typography sx={{ mb: 2 }}>{message}</Typography>
          <Button variant="contained" color="error" onClick={onClose}>
            Dismiss
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export const ContentModal = ({
  open,
  message,
  children,
  isLoading,
  confirmDisabled,
  onClose,
  onConfirm,
}: ContentModalProps) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="warning-modal-title"
        aria-describedby="warning-modal-description"
      >
        <Box
          sx={{ ...modalStyle, top: "50%", width: "30%", alignItems: "center" }}
        >
          <Typography
            id="warning-modal-description"
            variant="h4"
            sx={{ mb: 3 }}
          >
            {message}
          </Typography>
          <>{children}</>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              onClick={onClose}
              sx={{
                mr: 2,
                bgcolor: "#282b30",
                color: "white",
                "&:hover": {
                  bgcolor: "#424549",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              disabled={confirmDisabled}
              sx={{
                mr: 2,
                bgcolor: "white",
                color: "black",
                "&:hover": {
                  bgcolor: "darkgrey",
                },
              }}
            >
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "24px",
                  }}
                >
                  <CircularProgress size={24} style={{ color: "black" }} />
                </Box>
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
