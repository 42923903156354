import { getAxiosInstance } from "../utils/axios";

export const verifyAuthToken = async (props: { authToken: string }) => {
  try {
    const axiosInstance = await getAxiosInstance(props.authToken);
    const response = await axiosInstance.get(`/auth/token`);
    return response;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Unknown error");
    }
  }
};
