import { useEffect, ReactNode } from 'react';
import { verifyAuthToken } from '../../api/auth';
import { useCookies } from 'react-cookie';
import { TOKEN_KEY } from '../../constants';
import { useNavigate } from 'react-router-dom';

type AuthenticatorProps = {
    children: ReactNode
}

export const Authenticator = ({ children }: AuthenticatorProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _setCookie, removeCookie] = useCookies([TOKEN_KEY]);
  const authToken = cookies.user_token;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
          await verifyAuthToken({authToken: authToken})
      } catch (error) {
        removeCookie(TOKEN_KEY)
      }
  }
    if (cookies.user_token) {
      fetchData()
      }
    else{
      removeCookie(TOKEN_KEY)
      navigate('/auth')
    }
  }, [authToken, cookies.user_token, navigate, removeCookie]);

  return <>{children}</>;
}