import { Box, Typography } from "@mui/material"

type ErrorProps = {
    type: string,
    label: string
}

export const ErrorComponent = ({type, label}: ErrorProps) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
            <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '50%',
            width: '35%',
            bgcolor: '#fcd9d6',
            borderRadius: '1rem',
            borderStyle: 'solid',
            borderColor: 'red'
            }}>
                <Typography
                variant="h3"
                noWrap
                component="div"
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'red',
                    textDecoration: 'none',
                    maxWidth: '90%',
                    whiteSpace: 'normal',
                    mt: 8
                }}
                >
                    ERROR! [{type}]
                </Typography>
                <Typography
                variant="h5"
                noWrap
                component="div"
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.1rem',
                    color: 'black',
                    textDecoration: 'none',
                    maxWidth: '90%',
                    whiteSpace: 'normal',
                    mt: 10
                }}
                >
                    {label}
                </Typography>
            </Box>
        </Box>
    );
}
