import { getAxiosInstance } from "../utils/axios";

export const listAllCurricula = async (props: { authToken: string }) => {
  try {
    const axiosInstance = await getAxiosInstance(props.authToken);
    const response = await axiosInstance.get(`/curriculum`);
    return response;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Unknown error");
    }
  }
};


export const createCurriculum = async (props: { authToken: string, skill: string }) => {
  try {
    const axiosInstance = await getAxiosInstance(props.authToken);
    const response = await axiosInstance.post(`/curriculum`, { skill: props.skill });
    return response;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Unknown error");
    }
  }
};


export const deleteCurriculum = async (props: { authToken: string, sk: string }) => {
  try {
    const axiosInstance = await getAxiosInstance(props.authToken);
    const response = await axiosInstance.delete(`/curriculum/${props.sk}`);
    return response;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Unknown error");
    }
  }
};