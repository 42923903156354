export const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    color: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    borderStyle: "solid",
    borderColor: "white"
  };