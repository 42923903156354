import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { listAllCurricula, createCurriculum, deleteCurriculum } from "../../api/curriculum";
import { CustomRow, CurriculumState, EmptyRow } from "./customrow";
import { CustomTablePagination } from "./customtablepagination";
import { SuccessModal, ErrorModal, ContentModal } from "../../components/modal";
import { TOKEN_KEY } from "../../constants";

export type CurriculumObject = {
  sk: string;
  skill: string;
  state: CurriculumState;
  state_reason: string;
  start_time: string;
  end_time: string | null;
  sheet_link: string | null;
};

type InputColorType =
  | "info"
  | "error"
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | undefined;

export const DashboardPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rowsPerPage, _setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [curricula, setCurricula] = useState<CurriculumObject[]>([]);
  const [listCurriculaIsLoading, setListCurriculaIsLoading] = useState(true);
  const [createCurriculumIsLoading, setCreateCurriculumIsLoading] = useState(false);
  const [deleteCurriculumIsLoading, setDeleteCurriculumIsLoading] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isNewModalDataValid, setIsNewModalDataValid] = useState(false);
  const [newInputColor, setNewInputColor] = useState<InputColorType>("error");
  const [newInvalidReason, setNewInvalidReason] = useState(
    " - Skill name cannot be empty"
  );
  const [newSkill, setNewSkill] = useState("");
  const [currentCurriculum, setCurrentCurriculum] = useState<CurriculumObject | null>(null);
  const [deleteTooltip, setDeleteTooltip] = useState("Select a curriculum to be able to delete");
  const [successModalData, setSuccessModalData] = useState("");
  const [errorModalData, setErrorModalData] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _setCookie, _removeCookie] = useCookies([TOKEN_KEY]);
  const authToken = cookies[TOKEN_KEY];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - curricula.length) : 0;

  useEffect(() => {
    if (listCurriculaIsLoading === true) {
      const fetchData = async () => {
        try {
          const response = await listAllCurricula({ authToken: authToken });
          const all_campus_data: CurriculumObject[] = response.data;
          setCurricula(all_campus_data);
          setListCurriculaIsLoading(false);
        } catch (error: any) {
          setListCurriculaIsLoading(false);
        }
      };
      fetchData();
    }
  }, [authToken, listCurriculaIsLoading]);

  useEffect(() => {
    console.log(currentCurriculum);
    console.log(deleteTooltip);
    if (currentCurriculum === null) {
      setDeleteTooltip("Select a curriculum to be able to delete");
    } else if (
      currentCurriculum.state !== CurriculumState.FAILED &&
      currentCurriculum.state !== CurriculumState.COMPLETED
    ) {
      setDeleteTooltip("Currently selected curriculum is still ongoing");
    } else {
      setDeleteTooltip("");
    }
  }, [currentCurriculum, deleteTooltip]);

  const handleOpenCreationModal = () => {
    setCurrentCurriculum(null);
    setIsNewModalOpen(true);
  };

  const handleDelete = () => {
    setDeleteCurriculumIsLoading(true);
    const fetchData = async () => {
      try {
        await deleteCurriculum({
          authToken: authToken,
          sk: currentCurriculum!.sk,
        });
        setListCurriculaIsLoading(true);
        setDeleteCurriculumIsLoading(false);
        setCurrentCurriculum(null);
      } catch (error: any) {
        setListCurriculaIsLoading(true);
        setErrorModalData(error.message);
        setDeleteCurriculumIsLoading(false);
      }
    };
    fetchData();
  };

  const handleCloseNewModal = () => {
    setIsNewModalOpen(false);
  };

  const handleRefresh = () => {
    setListCurriculaIsLoading(true);
  };

  const handleNewDisabled = () => {
    const isAnyRunning = curricula.some(
      (obj) =>
        obj.state !== CurriculumState.FAILED &&
        obj.state !== CurriculumState.COMPLETED
    );
    return isAnyRunning;
  };

  const handleNewDataChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_skill = e.target.value;
    setNewSkill(e.target.value);
    if (new_skill.length === 0) {
      setIsNewModalDataValid(false);
      setNewInputColor("error");
      setNewInvalidReason(" - Skill name cannot be empty");
    } else if (new_skill.includes("-")) {
      setIsNewModalDataValid(false);
      setNewInputColor("error");
      setNewInvalidReason(" - No hyphens allowed in skill");
    } else {
      setIsNewModalDataValid(true);
      setNewInputColor("info");
    }
  };

  const handleCreate = () => {
    setCreateCurriculumIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await createCurriculum({
          authToken: authToken,
          skill: newSkill,
        });
        const new_sk = response.data.sk;
        setSuccessModalData(
          `Successfully created curriculum with sk: ${new_sk}`
        );
        setListCurriculaIsLoading(true);
        setIsNewModalOpen(false);
        setCreateCurriculumIsLoading(false);
      } catch (error: any) {
        setListCurriculaIsLoading(true);
        setErrorModalData(error.message);
        setIsNewModalOpen(false);
        setCreateCurriculumIsLoading(false);
      }
    };
    fetchData();
  };

  const isDeleteDisabled = () => {
    if (currentCurriculum === null) {
      return true;
    }
    if (
      currentCurriculum.state !== CurriculumState.FAILED &&
      currentCurriculum.state !== CurriculumState.COMPLETED
    ) {
      return true;
    }
    return false;
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <>
      <SuccessModal
        open={successModalData !== ""}
        message={successModalData}
        onClose={() => {
          setSuccessModalData("");
        }}
      />
      <ErrorModal
        open={errorModalData !== ""}
        message={errorModalData}
        onClose={() => {
          setErrorModalData("");
        }}
      />
      <ContentModal
        open={isNewModalOpen}
        onClose={handleCloseNewModal}
        message={"Enter the skill name for your new curriculum:"}
        children={
          <Box
            border="0.15rem  solid"
            borderColor={newInputColor}
            sx={{
              bgcolor: "lightgray",
              px: 1,
              py: 0.5,
              borderRadius: "0.2rem",
              mb: 2,
              mt: 1,
            }}
          >
            <FormControl variant="standard">
              <InputLabel
                color={newInputColor}
                sx={{
                  fontWeight: "bold",
                }}
              >
                Skill Name{newInvalidReason}
              </InputLabel>
              <Input
                color={newInputColor}
                onChange={handleNewDataChanges}
                startAdornment={
                  <InputAdornment position="start">
                    {isNewModalDataValid ? (
                      <DriveFileRenameOutlineIcon />
                    ) : (
                      <CloseIcon />
                    )}
                  </InputAdornment>
                }
                sx={{
                  mb: 2,
                  mt: 1,
                  border: "solid",
                  bgcolor: "white",
                  borderColor: { newInputColor },
                  borderRadius: "0.2rem",
                  px: 1,
                  padding: 1,
                }}
              />
            </FormControl>
          </Box>
        }
        isLoading={createCurriculumIsLoading}
        onConfirm={handleCreate}
        confirmDisabled={!isNewModalDataValid}
      />
      {listCurriculaIsLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress style={{ color: "black" }} size={200} />
        </Box>
      ) : (
        <>
          <Box
            sx={{ display: "flex", justifyContent: "center", height: "80vh" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "45%",
                m: 4,
                userSelect: "none",
              }}
            >
              <Box
                sx={{
                  p: 1,
                  px: 4,
                  border: "0.15rem solid",
                  borderColor: "black",
                  bgcolor: "black",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 500,
                    letterSpacing: ".15rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {"// Dashboard"}
                </Typography>
                <CachedIcon
                  onClick={handleRefresh}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  p: 1,
                  px: 2,
                  border: "0.15rem solid",
                }}
              >
                <TableContainer>
                  {curricula
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: CurriculumObject) => (
                      <CustomRow
                        key={row.sk}
                        sk={row.sk}
                        skill={row.skill}
                        state={row.state}
                        state_reason={row.state_reason}
                        start_time={row.start_time}
                        end_time={row.end_time}
                        sheet_link={row.sheet_link}
                        currentCurriculum={currentCurriculum}
                        setCurrentCurriculum={setCurrentCurriculum}
                      />
                    ))}
                  {emptyRows > 0 && <EmptyRow count={emptyRows} />}
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: 1,
                  }}
                >
                  <CustomTablePagination
                    rowsPerPageOptions={[5]}
                    count={curricula.length}
                    onPageChange={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    slotProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                        slots: {
                          firstPageIcon: FirstPageRoundedIcon,
                          lastPageIcon: LastPageRoundedIcon,
                          nextPageIcon: ChevronRightRoundedIcon,
                          backPageIcon: ChevronLeftRoundedIcon,
                        },
                      },
                    }}
                  />
                  <Box sx={{ display: "flex" }}>
                    <Tooltip title={deleteTooltip} placement="left" arrow>
                      <Box>
                        <Button
                          disabled={isDeleteDisabled()}
                          onClick={handleDelete}
                          sx={{
                            color: "white",
                            bgcolor: "#c62828",
                            maxHeight: "3rem",
                            mr: 5,
                            ":hover": {
                              backgroundColor: "maroon",
                            },
                            ":disabled": {
                              backgroundColor: "grey",
                              opacity: 0.4,
                              color: "black",
                            },
                          }}
                        >
                          {deleteCurriculumIsLoading ? (
                            <CircularProgress size={20} sx={{color: "white"}} />
                          ) : (
                            <Typography>Delete</Typography>
                          )}
                        </Button>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={
                        handleNewDisabled()
                          ? "Can only have one ongoing generation at a time"
                          : ""
                      }
                      placement="right"
                    >
                      <Box>
                        <Button
                          disabled={handleNewDisabled()}
                          onClick={handleOpenCreationModal}
                          sx={{
                            color: "white",
                            bgcolor: "black",
                            maxHeight: "3rem",
                            ":hover": {
                              backgroundColor: "darkgrey",
                              color: "black",
                            },
                            ":disabled": {
                              backgroundColor: "#424242",
                              opacity: 0.4,
                              color: "black",
                            },
                          }}
                        >
                          <Typography>New</Typography>
                        </Button>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
