import axios from "axios";
import { REST_API_URL } from "../constants";

export const getAxiosInstance = async (token: string) => {
  const axiosInstance = axios.create({
    baseURL: REST_API_URL,
  });
  axiosInstance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers.Accept = "application/json";
      config.headers["Content-Type"] = "application/json";

      return config;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
