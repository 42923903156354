import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Icon from '@mui/material/Icon';


export function Navbar() {

    const currentPath = window.location.href.split('/').slice(-1).pop();
    return (
        <AppBar position="static" sx={{ bgcolor: 'black' }}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <a href={`/${currentPath}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Icon sx={{
                            mr: 1,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            }}>
                            <img src={'ti_logo.png'} alt="TI Logo" />
                        </Icon>
                    </a>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href={`/${currentPath}`}
                        sx={{
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                        TI // CURRICULUM
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}