import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Button,
  Container,
  Grid,
  Card,
  Typography,
  Divider,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Input,
  Box,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { WarningModal } from "../../components/modal";
import { verifyAuthToken } from "../../api/auth";
import { TOKEN_KEY } from "../../constants";
import { useNavigate } from "react-router-dom";

type ModalState = {
  showSessionModal: string;
  setShowSessionModal: React.Dispatch<React.SetStateAction<string>>;
};

type InputColorType =
  | "info"
  | "error"
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | undefined;

type AuthStateType = "waiting" | "error" | "success";

export const AuthPage = ({
  showSessionModal,
  setShowSessionModal,
}: ModalState) => {
  const navigate = useNavigate();
  const handleClose = () => setShowSessionModal("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookie, removeCookie] = useCookies([TOKEN_KEY]);

  const [inputValue, setInputValue] = useState("");
  const [inputColor, setInputColor] = useState<InputColorType>("info");
  const [inputBorderColor, setInputBorderColor] = useState("black");
  const [isLoading, setIsLoading] = useState(false);
  const [authState, setAuthState] = useState<AuthStateType>("waiting");

  useEffect(() => {
    removeCookie(TOKEN_KEY);
  }, [removeCookie]);

  const handleVerify = async () => {
    setIsLoading(true);
    try {
      let authToken = inputValue;
      await verifyAuthToken({ authToken: authToken });
      setCookie(TOKEN_KEY, authToken);
      setInputColor("success");
      setInputBorderColor("green");
      setAuthState("success");
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    } catch (error: any) {
      setIsLoading(false);
      setInputColor("error");
      setInputBorderColor("red");
      setAuthState("error");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setAuthState("waiting");
  };

  const resetTextField = () => {
    setInputColor("info");
    setInputBorderColor("black");
  };

  return (
    <>
      <Container maxWidth="lg">
        <WarningModal
          open={showSessionModal !== ""}
          message={"Session Expired. Please re-enter your auth token."}
          onConfirm={handleClose}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ height: "90vh" }}
        >
          <Grid item xs={12}>
            <Card
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "black",
                color: "white",
                mx: "25%",
                boxShadow: 24,
                borderStyle: "solid",
                borderColor: "white",
                borderWidth: 2,
              }}
            >
              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 200,
                  letterSpacing: ".05rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                TI - Multiagent Curriculum
              </Typography>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  mb: 2,
                  mt: 1,
                  borderColor: "white",
                  width: "50%",
                  alignSelf: "center",
                }}
              />
              <Box
                border="0.15rem  solid"
                borderColor={inputBorderColor}
                sx={{
                  bgcolor: "lightgray",
                  px: 1,
                  py: 0.5,
                  borderRadius: "0.2rem",
                  mb: 2,
                  mt: 1,
                }}
              >
                <FormControl variant="standard">
                  <InputLabel
                    color={inputColor}
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Auth key
                  </InputLabel>
                  <Input
                    type="password"
                    color={inputColor}
                    onInput={resetTextField}
                    onChange={handleInputChange}
                    startAdornment={
                      <InputAdornment position="start">
                        {authState === "waiting" ? (
                          <KeyIcon />
                        ) : authState === "success" ? (
                          <DoneIcon />
                        ) : (
                          <CloseIcon />
                        )}
                      </InputAdornment>
                    }
                    sx={{
                      mb: 2,
                      mt: 1,
                      border: "solid",
                      bgcolor: "white",
                      borderColor: { inputBorderColor },
                      borderRadius: "0.2rem",
                      px: 1,
                      padding: 1,
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  height: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleVerify}
                  sx={{
                    bgcolor: "#424549",
                    "&:hover": { bgcolor: "#282b30" },
                    p: 1,
                    width: "7rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      fontFamily: "monospace",
                      fontWeight: 200,
                      letterSpacing: ".1rem",
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={35} sx={{ color: "white" }} />
                    ) : (
                      "VERIFY"
                    )}
                  </Typography>
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
