import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Close from "@mui/icons-material/Close";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { CurriculumObject } from ".";

type CustomRowProps = {
  sk: string;
  skill: string;
  state: CurriculumState;
  state_reason: string;
  start_time: string;
  end_time: string | null;
  sheet_link: string | null;
  currentCurriculum: CurriculumObject | null;
  setCurrentCurriculum: React.Dispatch<
    React.SetStateAction<CurriculumObject | null>
  >;
};

type EmptyRowProps = {
  count: number;
};

export enum CurriculumState {
  FAILED = "-1",
  CREATED = "0",
  FETCHING_RESOURCES = "1",
  ONTOLOGY_SKELETON = "2",
  GENERATING_ONTOLOGY = "3",
  GRADING_AND_LEARNING = "4",
  COMPLETED = "5",
}

export const getStateString = (state: CurriculumState): string => {
  switch (state) {
    case CurriculumState.FAILED:
      return "FAILED";
    case CurriculumState.CREATED:
      return "CREATED";
    case CurriculumState.FETCHING_RESOURCES:
      return "FETCHING_RESOURCES";
    case CurriculumState.ONTOLOGY_SKELETON:
      return "ONTOLOGY_SKELETON";
    case CurriculumState.GENERATING_ONTOLOGY:
      return "GENERATING_ONTOLOGY";
    case CurriculumState.GRADING_AND_LEARNING:
      return "GRADING_AND_LEARNING";
    case CurriculumState.COMPLETED:
      return "COMPLETED";
    default:
      return "UNKNOWN";
  }
};

export const CustomRow: React.FC<CustomRowProps> = ({
  sk,
  skill,
  state,
  state_reason,
  start_time,
  end_time,
  sheet_link,
  currentCurriculum,
  setCurrentCurriculum,
}) => {
  const status = getStateString(state);
  const [selected, setSelected] = useState(false);
  const title = `${skill} ${sk.split("-").slice(-1).pop()}`;
  const summarizedTitle =
    title.length > 20 ? `${title.slice(0, 20)}...` : title;
  const summarizedSk = sk.length > 25 ? `${sk.slice(0, 25)}...` : sk;
  const visibleStateReason =
    state === CurriculumState.FAILED || state === CurriculumState.COMPLETED
      ? state_reason
      : "";
  const tooltipContent =
    summarizedTitle < title ? (
      <React.Fragment>
        <Typography color="inherit">{title}</Typography>
        <strong>{sk}</strong>
      </React.Fragment>
    ) : (
      ""
    );
  const createdOn = start_time.split("T")[0];
  const border = selected ? "0.3rem solid" : "0.15rem solid";
  const backgroundColor = selected ? "lightgray" : "white";
  const borderColor = selected
    ? "black"
    : status === "FAILED"
      ? "red"
      : status === "COMPLETED"
        ? "green"
        : "#2196f3";
  const padding = selected ? "0.875rem" : "1rem";
  const totalSteps = Object.keys(CurriculumState).length - 2;
  const progress = Math.floor((parseInt(state) / totalSteps) * 100);

  useEffect(() => {
    if (currentCurriculum?.sk === sk) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [currentCurriculum, sk]);

  const handleClick = () => {
    if (!selected) {
      setCurrentCurriculum({
        sk,
        skill,
        state,
        state_reason,
        start_time,
        end_time,
        sheet_link,
      });
    } else {
      setCurrentCurriculum(null);
    }
    setSelected(!selected);
  };

  return (
    <HtmlTooltip title={tooltipContent} placement="left" arrow>
      <Box
        sx={{
          backgroundColor: backgroundColor,
          border: { border },
          borderColor: { borderColor },
          borderRadius: "0.5rem",
          padding: { padding },
          marginBottom: "1rem",
          maxHeight: "4rem",
          userSelect: "none",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mr: 10,
              minWidth: "26%",
            }}
          >
            <Typography variant="h6">{summarizedTitle}</Typography>
            <Typography variant="body2" color="textSecondary">
              {summarizedSk}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {createdOn}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mr: 5,
              minWidth: "5%",
            }}
          >
            <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
              Progress
            </Typography>
            {state === CurriculumState.FAILED ? (
              <Close sx={{ color: borderColor }} />
            ) : (
              <CircularProgressWithLabel value={progress} />
            )}
          </Box>
          <Tooltip title={visibleStateReason} placement="top">
            <Typography
              variant="body1"
              sx={{ minWidth: "30%", mr: 5, color: borderColor }}
            >
              {status}
            </Typography>
          </Tooltip>
          {sheet_link && (
            <Tooltip title="Google Sheets" placement="right" arrow>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: "5%",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 0.5 }}
                >
                  Sheet Link
                </Typography>
                <a href={sheet_link} rel="noreferrer" target="_blank">
                  <ListAltIcon />
                </a>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
    </HtmlTooltip>
  );
};

export const EmptyRow: React.FC<EmptyRowProps> = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Box
          key={index}
          sx={{
            padding: "1rem",
            marginBottom: "16px",
            minHeight: "4.25rem",
            maxHeight: "4.25rem",
          }}
        ></Box>
      ))}
    </>
  );
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
